.main-navigation__menu-btn {
  background: #eee;
  border: none;
  padding-top: 0px;
  margin-left: 10px;
  float: right;
}


.main-navigation__title {
  margin-left: 10px;
  width: 150px;
  float: left;
}

.main-navigation__title a {
  text-decoration: none;
  font-family: Arial, sans-serif, sans-serif;
  font-weight:bold;
  font-size: 35px;
  color: rgb(0, 0, 0);
}

.main-navigation__header-nav {
  width: auto;
  height: auto;
  border: none;
  float: right;
  background-color: #eee;

}

.main-navigation__drawer-nav {
  height: 100%;
  background: rgb(63, 190, 37);
  margin-right: 100px;
}

.hamburger {
  font-size: 40px;
}

.MuiDrawer-paper {
  background-color: #fff !important;
  padding: 40px 20px;
}