.registration__header {
  color: white;
  text-align: center;
}

.registration {
  width: 90%;
  max-width: 30rem;
  margin: 1rem ;
  text-align: center;
}

.registration form {
  margin-bottom: 1rem;
}