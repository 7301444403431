
/* bet item stuff */
.fab {
  position: absolute !important;
  right: 20px;
  bottom: 20px;
};


/* bet header stuff */
.bet-item {
  margin-top: 1rem;
  margin-bottom: 2rem;
  width: 95%;
  min-width: 300px;
}

.bet-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 1rem;
  color: red;
  
}

.bet-item a:hover,
.bet-item a:active {
  background: #5900ff;
}

.bet-item__content {
  padding: 0;
}

.bet-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.bet-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: white;
}

.bet-item:hover h2,
.bet-item:active h2,
.bet-item:hover h3,
.bet-item:active h3 {
  color: #292929;
}

.bet-item__info h3 {
  margin: 0;
}

.action-block {
  position: relative;
  left: 200px;
};

.status-block {
  padding: 5px 5px;
  margin-right: -10px;
  margin-top: -12px;
  font-size: large;
  font-weight: bold;
}

.yes-no-block {
  display: inline-flex;
}

.display-block {
  color: blue;
  padding-bottom: 10px;
}

.bet-item-status-block {
  background-color: #0094ff;
  padding: 5px 10px !important;
}

.bet-item-comments-block {
  background-color: yellow;
  padding: 5px 10px !important;
}

/* bet colors */

.bet-item-color-invite {
  background-color: #eee !important;
  border: 1px solid #0094ff;
}

.bet-item-color-inplay {
  background-color: #fff !important;
  border: 1px solid #e5e5e5;
}

.bet-item-color-arbd {
  background-color: #fff !important;
  border: 1px solid black;
}

.status-block-color-invite {
  background-color: red;
  color: white;
}

.status-block-color-sent {
  background-color: black;
  color: white;
}

.status-block-color-inplay {
  background-color: black;
  color: white;
}

.status-block-color-inarb {
  background-color: black;
  color: white;
}

.status-block-color-won {
  background-color: #3dcc00;
  color: white;
}

.status-block-color-lost {
  background-color: #ff0000;
  color: white;
}