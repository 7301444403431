.topic-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}

.topic-item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: white;
  background: #292929;
}

.topic-item a:hover,
.topic-item a:active {
  background: #ffd900;
}

.topic-item__content {
  padding: 0;
}

.topic-item__image {
  width: 4rem;
  height: 4rem;
  margin-right: 1rem;
}

.topic-item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: black;
}

.topic-item:hover h2,
.topic-item:active h2,
.topic-item:hover h3,
.topic-item:active h3 {
  color: #292929;
}

.topic-item__info h3 {
  margin: 0;
}