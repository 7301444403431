.image-stack {
  display: grid;
  grid-template-columns: repeat(12, 1fr);
  position: relative;
}

.image-stack__item--top {
  grid-column: 4 / span 8;
  grid-row: 1; 
  padding-top: 20%; 
  z-index: 1;
}

.image-stack__item--bottom {
  grid-column: 3 / -1;
  grid-row: 1; 
}