.main-header {
  width: 100%;
  height: 60px;
  background-color: #eee;
  padding: 10px;
  z-index: 0;
  margin: 0 auto;
  display: block;
}

main {
  margin-top: 2rem;
}
