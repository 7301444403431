.nav-links {
  list-style: none;
}

.nav-links li {
  /* nada */
}

.nav-links a {
  text-decoration: none;
  padding: 0px;
}

.nav-links a:hover,
.nav-links a:active,
.nav-links a.active {
  color: #292929;
}

.nav-links button {
  cursor: pointer;
  background: transparent;
  font: inherit;
}

.nav-links button:focus {
  outline: none;
}

.nav-links button:hover,
.nav-links button:active {
  color: white;
}
