.registration__header {
  color: white;
  text-align: center;
}

.testpanel {
  width: 400px;
  text-align: center;
}

.registration form {
  margin-bottom: 1rem;
}