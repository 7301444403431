.item {
  margin: 1rem;
  width: calc(100% - 2rem);
  min-width: 17.5rem;
}

.item a {
  display: flex;
  align-items: center;
  width: 100%;
  height: 100%;
  text-decoration: none;
  padding: 1rem;
  color: red;
  background: #292929;
}

.item a:hover,
.item a:active {
  background: #ffd900;
}

.item__content {
  padding: 0;
}

.item__image {
  width: 5rem;
  height: 5rem;
  margin-right: 1rem;
}

.item__info h2 {
  font-size: 1.5rem;
  margin: 0 0 0.5rem 0;
  font-weight: normal;
  color: #292929;
}

.item:hover h2,
.item:active h2,
.item:hover h3,
.item:active h3 {
  color: #580303;
}

.item__info h3 {
  margin: 0;
}