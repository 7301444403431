.authentication__header {
  color: rgb(179, 15, 15);
  text-align: center;
}

.authentication {
  width: 40%;
  min-width: 370px;
  margin: 1rem ;
  
  background-color: #fff;
  float: left;
}

.authentication form {
  margin-bottom: 1rem;
}