.yesNormal {
  color:green;
  background-color: white;
}

.yesArbitrated {
  color: white;
  background-color: green;
}

.noNormal {
  color: red;
  background-color: white;
}

.noArbitrated {
  color: white;
  background-color: red;
}

.yes-no{
  display: flex;
}