.game-item {
  min-width: 300px;
  background: white;
}


.game-item-comments-block {
  height: 25px;
  background-color: white;
  text-align: left;
  padding: 0px 10px !important;
}

