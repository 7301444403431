.admin-list {
  list-style: none;
  margin: 0 auto;
  padding: 0;
  width: 90%;
  max-width: 50rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.admin-item {
  margin: 1rem;
  width: calc(45% - 2rem);
  min-width: 17.5rem;
}